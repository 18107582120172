import './scss/style.scss'

{ // country selector
  const optionsDictionary = {
    RU: {
      label: 'Россия',
      phone: '+7 (495) 669-67-21'
    },
    UZ: {
      label: 'Узбекистан',
      phone: '+998 (78) 113-22-66'
    },
    KZ: {
      label: 'Казахстан',
      phone: '+7 (708) 905-70-02'
    }
  }

  const priceWithCountryDictionary = {
    RU: '600р в месяц за 1 пользователя.',
    UZ: '90 000 сум в месяц за 1 пользователя.',
    KZ: '600р в месяц за 1 пользователя для юридических лиц, <br> 9$ в месяц за одного пользователя для физических лиц.'
  }

  const countrySelectorOptionItemElem = document.querySelectorAll('.onpbx-country-selector__item')
  const countrySelectorNumResult = document.querySelector('.onpbx-country-selector__number-selected')
  const countrySelectorNameResult = document.querySelector('.onpbx-country-selector__country-selected')
  const countrySelectorOptionsList = document.querySelector('.onpbx-country-selector__options-list')

  const priceTextElem = document.querySelector('.onpbx-faq__accordion-item-text_price')

  const footerBlocksRU = document.querySelectorAll('.onpbx-main-footer__block_RU')
  const footerBlocksUZ = document.querySelectorAll('.onpbx-main-footer__block_UZ')
  const footerBlocksKZ = document.querySelectorAll('.onpbx-main-footer__block_KZ')

  const footerPolicyLinksRU = document.querySelectorAll('.onpbx-main-footer__policy-links_RU')
  const footerPolicyLinksUZ = document.querySelectorAll('.onpbx-main-footer__policy-links_UZ')
  const footerPolicyLinksKZ = document.querySelectorAll('.onpbx-main-footer__policy-links_KZ')

  const footerContactsWithCountryDictionary = {
    RU: {
      contacts: footerBlocksRU,
      policy: footerPolicyLinksRU
    },
    UZ: {
      contacts: footerBlocksUZ,
      policy: footerPolicyLinksUZ
    },
    KZ: {
      contacts: footerBlocksKZ,
      policy: footerPolicyLinksKZ
    },
  }

  countrySelectorNameResult.addEventListener('click', () => {
    if (countrySelectorOptionsList.classList.contains('onpbx-country-selector__options-list_showed')) {
      countrySelectorOptionsList.classList.remove('onpbx-country-selector__options-list_showed')
      countrySelectorNameResult.classList.remove('onpbx-country-selector__country-selected_showed')
    } else {
      countrySelectorOptionsList.classList.add('onpbx-country-selector__options-list_showed')
      countrySelectorNameResult.classList.add('onpbx-country-selector__country-selected_showed')
    }
  })

  countrySelectorOptionItemElem.forEach(option => {
    option.addEventListener('click', function(e) {
      const selectedCountry = this.dataset.country

      countrySelectorNumResult.innerHTML = optionsDictionary[selectedCountry].phone
      countrySelectorNameResult.innerHTML = optionsDictionary[selectedCountry].label

      priceTextElem.innerHTML = priceWithCountryDictionary[selectedCountry]

      Object.keys(footerContactsWithCountryDictionary).forEach(countryKey => {
        if (countryKey !== selectedCountry) {
          footerContactsWithCountryDictionary[countryKey].contacts.forEach((contacts) => {
            contacts.classList.add('onpbx-main-footer__block_hidden')
          })

          footerContactsWithCountryDictionary[countryKey].policy.forEach((policy) => {
            policy.classList.add('onpbx-main-footer__policy-links_hidden')
          })
        }
      })

      footerContactsWithCountryDictionary[this.dataset.country].contacts.forEach((contacts) => {
        contacts.classList.remove('onpbx-main-footer__block_hidden')
      })

      footerContactsWithCountryDictionary[this.dataset.country].policy.forEach((policy) => {
        policy.classList.remove('onpbx-main-footer__policy-links_hidden')
      })

      countrySelectorOptionItemElem.forEach(opt => {
        opt.classList.remove('onpbx-country-selector__item_selected')
      })

      countrySelectorOptionsList.classList.remove('onpbx-country-selector__options-list_showed')
      countrySelectorNameResult.classList.remove('onpbx-country-selector__country-selected_showed')
      this.classList.add('onpbx-country-selector__item_selected')
    })
  })

  document.addEventListener('click', (e) => {
    if (e.target === countrySelectorOptionsList || e.target === countrySelectorOptionItemElem || e.target === countrySelectorNameResult) return undefined

    countrySelectorOptionsList.classList.remove('onpbx-country-selector__options-list_showed')
    countrySelectorNameResult.classList.remove('onpbx-country-selector__country-selected_showed')
  })
}

{ // whatsapp widget
  const whatsappBtnElem = document.querySelector('.onpbx-whatsapp-widget__icon-btn')
  const whatsappModalContentElem = document.querySelector('.onpbx-whatsapp-widget__modal-content')
  const whatsappModalElem = document.querySelector('.onpbx-whatsapp-widget__modal')
  const whatsappModalCloseIcon = document.querySelector('.onpbx-whatsapp-widget__modal-close')

  whatsappBtnElem.addEventListener('click', () => {
    whatsappModalElem.classList.add('onpbx-whatsapp-widget__modal_showed')
  })

  whatsappModalCloseIcon.addEventListener('click', () => {
    whatsappModalElem.classList.remove('onpbx-whatsapp-widget__modal_showed')
  })

  whatsappModalElem.addEventListener('click', function(e) {
    if (e.target === whatsappModalContentElem) return undefined

    whatsappModalElem.classList.remove('onpbx-whatsapp-widget__modal_showed')
  })
}

{ // fixed top menu
  const headerTopElement = document.querySelector('.onbbx-main-header__top')
  const headerElement = document.querySelector('.onbbx-main-header')

  const setFixedHeader = () => {
    if (window.scrollY > 20) {
      headerTopElement.classList.add('onbbx-main-header__top_fixed')
      headerElement.style.paddingTop = headerTopElement.clientHeight + 64 + 'px'
    } else {
      headerTopElement.classList.remove('onbbx-main-header__top_fixed')
      headerElement.style.paddingTop = 0
    }
  }

  setFixedHeader()

  window.addEventListener('scroll', setFixedHeader)
}

{ // smooth scroll
 const mainMenuLinkElem = document.querySelectorAll('.onbbx-main-menu__link')

 mainMenuLinkElem.forEach(linkItem => {
   linkItem.addEventListener('click', function(e) {
     if (this.hash !== '') {
       e.preventDefault()

       const href = this.getAttribute('href')
       const offsetTop = document.querySelector(href).offsetTop

       scroll({
         top: offsetTop - 20,
         behavior: 'smooth'
       });
     }
   })
 })
}

{ // accordion
  const accordionTitleItems = document.querySelectorAll('.onpbx-faq__accordion-item-title')
  const accordionTextItems = document.querySelectorAll('.onpbx-faq__accordion-item-text')
  
  accordionTitleItems.forEach(item => {
    item.addEventListener('click', function (e) {
      accordionTextItems.forEach(textElem => {
        textElem.classList.remove('onpbx-faq__accordion-item-text_showed')
      })

      const textElem = document.querySelector(`.onpbx-faq__accordion-item-text[data-id='${this.dataset.id}']`);

      textElem.classList.add('onpbx-faq__accordion-item-text_showed')
    })
  })
}

{ // callback form
  const submitForms = document.querySelectorAll('.onpbx-form-callback');
  const inputs = document.querySelectorAll('.onpbx-form-callback__input');

  submitForms.forEach(function (evt, index) {
    evt.onsubmit = function (evt) {
      evt.stopPropagation();
      evt.preventDefault();

      ym(631388,'reachGoal','callback_lead');

      const agentID = document.cookie.replace(/(?:(?:^|.*;\s*)agentid\s*=\s*([^;]*).*$)|^.*$/, '$1');
      const url = new window.URL('https://panel.onlinepbx.ru/register');
      
      url.searchParams.append('email', inputs[index].value);
      window.location.href = url.toString();
    };
  });
}
